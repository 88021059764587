<template>
  <InputText
    style="text-align: right"
    @input="handleInput"
    @focus="editando = true"
    @blur="handleBlur"
    @update:model-value="handleBlur({ target: $event })"
    @keypress="handleKeyPress"
    :value="props.modelValue"
  />
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, onMounted } from 'vue'
import InputText from '@alterdata/component-vue/InputText/index.vue'
import { VALOR_INVALIDO } from '../../util/message'
import { utils } from '../../util/utils'

const props = defineProps({
  modelValue: String,
  casasDecimais: {
    type: Number,
    default: 2
  }
})

const emit = defineEmits(['update:modelValue', 'update:erro'])

const updateErro = (value) => emit('update:erro', { message: value })

const updateModel = (value) => emit('update:modelValue', value)

const editando = ref(false)

const input = ref(props.modelValue)

const decimais = ref(props.casasDecimais)

function handleBlur (event) {
  editando.value = true
  updateErro('')
  if (event.preventDefault) {
    event.preventDefault()
  }
  const { value } = event.target
  if (value) {
    const valueFloat = parseFloat(value.replace(',', '.')).toFixed(decimais.value)
    if (valueFloat >= 0.0 && valueFloat <= 100.0) {
      input.value = valueFloat > parseFloat('99.'.padEnd(3 + decimais.value, '9')) ? '100,'.padEnd(4 + decimais.value, 0) : valueFloat.replace('.', ',')
      updateModel(input.value)
    } else {
      updateErro(VALOR_INVALIDO)
    }
  }
}

function handleInput (event) {
  const theEvent = event || window.event
  const { value } = theEvent.target
  if (value) {
    updateErro('')
    const regex = new RegExp(`^100$|^100,[0]{1,${decimais.value}}$|^[0-9]{1,2}$|^[0-9]{1,2},[0-9]{1,${decimais.value}}$`, 'g')
    const valueFloat = parseFloat(value).toFixed(decimais.value)
    if (regex.test(value) && valueFloat >= 0.0 && valueFloat <= 100.0) {
      input.value = value
      updateModel(input.value)
    } else {
      if (!value.endsWith(',')) {
        theEvent.returnValue = false
        if (theEvent.preventDefault) {
          theEvent.preventDefault()
          theEvent.target.value = input.value
        }
      }
    }
  } else {
    updateModel(undefined)
  }
}

function handleKeyPress (event) {
  if (event instanceof KeyboardEvent) {
    utils.onlyForRegex(event, /[\d,]+/g)
  }
}

function toFloat (value) {
  const strValue = String(value)
  if (strValue.includes(',')) {
    if (strValue === ',') {
      return '0.00'
    } else {
      return parseFloat(strValue.replace(',', '.')).toFixed(decimais.value)
    }
  } else {
    return parseFloat(strValue).toFixed(decimais.value)
  }
}

onMounted(() => {
  if (props.modelValue !== null && props.modelValue !== undefined) {
    const handle = parseFloat(props.modelValue).toFixed(decimais.value).replace('.', ',')
    input.value = handle
    updateModel(input.value)
  }
})

watch(
  () => props.modelValue,
  () => {
    if (
      props.modelValue ||
      props.modelValue === 0 ||
      props.modelValue === '0,'.padEnd(2 + decimais.value, '0')
    ) {
      const handle = toFloat(props.modelValue).replace('.', ',')
      input.value = handle
      if (!editando.value) {
        updateModel(input.value)
      }
    } else {
      input.value = ''
    }
  }
)
</script>
