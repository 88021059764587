class Faixa {
  constructor (faixa) {
    this.id = undefined
    this.vlDe = undefined
    this.vlAte = undefined
    this.aliquota = undefined
    this.vlDesconto = undefined
    if (faixa instanceof Faixa) {
      Object.assign(this, faixa)
    } else if (faixa) {
      this.id = faixa.id
      this.vlDe = String(faixa.vlDe)
      this.vlAte = String(faixa.vlAte)
      this.aliquota = String(faixa.aliquota)
      this.vlDesconto = String(faixa.vlDesconto)
    }
  }
}

export default Faixa
