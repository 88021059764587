<template>
  <div>
    <p class="is-size-6">
      <strong>Faixas de valor da tabela</strong>
    </p>
    <div
      style="
        min-height: 53vh;
        max-height: 40vh;
        overflow: hidden;
        overflow-y: auto;
      "
    >
      <faixa-de-valor
        v-for="(faixaDeValores, index) in pFaixas"
        :ref="'faixa-' + index"
        :class="{ 'pr-1': maiorQueCincoItens }"
        :key="index"
        :p-key="String(index)"
        :p-faixa="faixaDeValores"
        @erro-faixa="tratarErroFaixa"
        @atualizar-faixas="atualizarFaixas()"
        @excluir-faixa="aoExcluirFaixa"
        @atualizar-proxima-faixa="aoAtualizarProximaFaixa"
      ></faixa-de-valor>
    </div>
    <div
      class="is-flex is-justify-content-center is-align-items-center"
      style="width: 100%"
    >
      <Button icon="plus" :title="shortkeys.MSG_KEY_NOVO" caption="Nova faixa" @click="adicionarNovaFaixa()" />
    </div>
  </div>
</template>

<script>
import FaixaDeValor from './FaixaDeValor.vue'
import Button from '@alterdata/component-vue/Button/index.vue'
import Faixa from '../model/Faixa'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
export default {
  components: {
    FaixaDeValor,
    Button
  },

  emits: ['erro-faixa', 'atualizar-lista', 'adicionar-faixa-para-exclusao'],

  props: {
    pFaixas: Array,
    pErros: Array
  },

  data () {
    return {
      faixas: this.pFaixas,
      faixasLocal: [],
      erros: this.pErros,
      shortkeys: shortkeys
    }
  },
  methods: {
    adicionarNovaFaixa () {
      const faixa = new Faixa()
      if (this.faixas[this.faixas.length - 1]) {
        const value = this.faixas[this.faixas.length - 1].vlAte
        if (value) {
          faixa.vlDe = parseFloat(value) + 0.01
        }
      }
      this.faixas.push(faixa)
    },
    tratarErroFaixa (event) {
      this.$emit('erro-faixa', event)
    },
    atualizarFaixas () {
      this.faixas.forEach((obj, index) => {
        const el = this.$refs['faixa-' + index][0]
        if (el) {
          el.verificaDados()
        }
      })
    },

    aoExcluirFaixa (event) {
      const indexFaixa = this.faixas.findIndex(f => f === event.faixa)
      this.$emit('adicionar-faixa-para-exclusao', event)
      this.faixas.splice(indexFaixa, 1)
    },
    aoAtualizarProximaFaixa (event) {
      const nEvent = Number(event)
      if (this.faixas[nEvent - 1]) {
        const faixa = this.faixas[nEvent - 1]
        if (faixa.vlAte) {
          this.faixas[nEvent].vlDe = parseFloat(Number(faixa.vlAte) + 0.01).toFixed(2)
        }
      }
    }
  },
  computed: {
    maiorQueCincoItens () {
      return this.faixas.length > 5
    }
  },
  watch: {
    pFaixas (val) {
      this.faixas = val
    }
  }
}
</script>

<style>
</style>
