<template>
  <div class="is-flex is-flex-direction-column is-desktop" id="irrf">
    <div class="column is-full">
      <page-title v-if="this.showTitle" >IRRF</page-title>
      <div class="level-left">
        <input-data
          :key="a++"
          type="month"
          label="Referência"
          id="referencia"
          maxlength="4"
          v-model="this.referencia"
          @change="
            this.carregar(this.referencia);
            this.setReferenciaDate()
          "
        />
        <div style="position: relative; top: 4px">
          <Button iconOnly="angle-left" @click="alterarReferencia(false)" />
          <Button iconOnly="angle-right" @click="alterarReferencia(true)" />
        <Button
          class="ml-2"
          :title="shortkeys.MSG_KEY_NOVO"
          caption="Cadastro manual da tabela"
          type="primary"
          @click="ativarModal"
        />
        </div>
        <div class="buttons">
        </div>
      </div>
      <alt-datatable
        ref="datatable"
        custom-search
        :pStatus="this.status"
        :pDataSource="this.dataSource"
        :pData="this.tabelaIrrf.faixas"
        :pHideButtonMore="this.hideButtonMore"
        :pShowButtons="false"
        :pQuantidadeDeItems="this.page.total"
        :paginacao="false"
        :idDivUtilizadaPaginacao="'irrf'"
        :custom-style="'max-height: 45vh'"
        :custom-highlighters="customHighlighters"
        :ativar-atalho-delete="false"
        :ativar-atalho-enter="false"
        :desativar-atalhos="showModal"
        @search="aoPesquisar"
      >
        <template v-slot:new-item>
          <div class="columns is-variable is-4">
            <div class="column">
              <input-texto
                :disabled="criando"
                label="Número de dependentes"
                v-model="tabelaIrrf.numeroDependentes"
                style="text-align: right"
              />
            </div>
            <div class="column">
              <InputMonetary
                :disabled="criando"
                label="Valor por dependente"
                v-model="tabelaIrrf.vlDependente"
              >
              </InputMonetary>
            </div>
            <div class="column">
              <InputMonetary
                :disabled="criando"
                label="IRRF mínimo"
                v-model="tabelaIrrf.vlIrrfMin"
              >
              </InputMonetary>
            </div>
          </div>
        </template>
      </alt-datatable>
    </div>
    <Modal
      style="z-index: 1001"
      title="Tabela IRRF"
      :active="showModal"
      @close="aoCancelarModal('close')"
    >
      <CadastroEdicao
      ref="cadastroEdicao"
        style="min-height: 100% !important"
        :pErros="this.erros"
        :pItem="tempIrrf"
        :pReferencia="this.referencia"
        :pReferenciaDate="this.referenciaDate"
        @gravar="aoGravar"
        @cancelar="aoCancelar('fechar')"
        @change-referencia="this.alterarReferencia($event)"
        @erro-faixa="tratarErroFaixa"
        @atualizar-lista="this.carregar(this.referencia)"
        @adicionar-faixa-para-exclusao="aoExcluirFaixa"
      />
      <template #footer>
        <ButtonAjuda :link-ajuda="linkAjuda" :title="shortkeys.MSG_KEY_AJUDA" style="margin-right: auto;"/>
        <Button
          class="mr-2"
          :title="shortkeys.MSG_KEY_GRAVAR"
          caption="Gravar"
          type="primary"
          @click="aoGravar"
        />
        <Button caption="Cancelar" :title="shortkeys.MSG_KEY_ESC" @click="aoCancelar('fechar')" />
      </template>
    </Modal>
  </div>
  <Modal
    title="Confirmação"
    :active="this.abrirModalImportacao"
    style="z-index: 1002"
    @close="this.abrirModalImportacao = false"
  >
    <p>
      Não foram encontrados dados da tabela de IRRF para o período selecionado.
      Deseja importar a tabela do período anterior?
    </p>
    <template v-slot:footer>
      <Button
        class="button is-primary"
        @click="this.confirmarImportacao()"
        caption="Sim"
        :loading="carregando"
        style="margin-right: 1rem"
      />
      <Button
        class="button"
        @click="this.abrirModalImportacao = false"
        caption="Não"
        :disabled="carregando"
      />
    </template>
  </Modal>
</template>

<script>
import Irrf from './model/Irrf'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service.js'
import { toast } from 'bulma-toast'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { mixinModalSaida } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-modal-saida-exclusao'
import { mixinRouterGuardian } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-router-guardian'
import InputDate from '@alterdata/component-vue/InputDate'
import Button from '@alterdata/component-vue/Button'
import DataTable from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'
import InputText from '@alterdata/component-vue/InputText'
import Modal from '@alterdata/component-vue/Modal'
import CadastroEdicao from './cadastroEdicao.vue'
import Faixa from './model/Faixa.js'
import InputMonetary from '@packonline/packonline-reutilizaveis-frontend/components/InputMonetary.vue'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import PageTitle from '@packonline/packonline-reutilizaveis-frontend/components/PageTitle.vue'

export default {
  components: {
    'alt-datatable': DataTable,
    'input-texto': InputText,
    'input-data': InputDate,
    Button,
    'page-title': PageTitle,
    Modal,
    CadastroEdicao,
    InputMonetary,
    ButtonAjuda
  },
  provide () {
    return {
      direcionaAjuda: (link) => this.direcionaAjuda(link)
    }
  },
  inject: ['setActiveSection', 'addShortcut'],
  mixins: [mixinFocoPrimeiroElementoDataTable, mixinModalSaida, mixin, mixinRouterGuardian],

  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'vlDe',
            label: 'Valor Inicial',
            format: this._formatarColunaValor,
            style: 'text-align:right',
            styleItems: 'text-align:right'
          },
          {
            name: 'vlAte',
            label: 'Valor Final',
            format: this._formatarColunaValor,
            style: 'text-align:right',
            styleItems: 'text-align:right'
          },
          {
            name: 'aliquota',
            label: 'Alíq.(%)',
            format: this._formatarColunaValor,
            style: 'text-align:right',
            styleItems: 'text-align:right'
          },
          {
            name: 'vlDesconto',
            label: 'Desconto',
            format: this._formatarColunaValor,
            style: 'text-align:right',
            styleItems: 'text-align:right'
          }
        ],
        selected: null,
        save: (item) => console.log('[save] ' + item),
        cursor: null,
        filter: () => this.carregar(this.referencia),
        loadMore: null
      },
      tabelaIrrf: new Irrf(),
      item: new Irrf(),
      page: [],
      status: '',
      hideButtonMore: false,
      showTitle: false,
      referencia: '',
      previousPage: null,
      abrirModalImportacao: null,
      referenciaDate: new Date(),
      options: [
        { title: 'Cadastro manual da tabela', action: this.ativarModal }
      ],
      criando: true,
      showModal: false,
      tempIrrf: null,
      erros: [],
      input: [],
      faixasParaExclusao: [],
      carregando: false,
      mensagemDePesquisaVazia: 'Não existem dados para visualizar',
      showMensagemVazia: false,
      termoPesquisado: '',
      listagemOriginal: [],
      shortkeys: shortkeys,
      linkAjuda: 'https://ajuda.alterdata.com.br/display/POD/Cadastrar+Tabela+de+IRRF',
      itemOriginal: null
    }
  },

  methods: {
    async carregar (referencia) {
      if (this.termoPesquisado) {
        await this.buscar(this.termoPesquisado, referencia)
      } else {
        this.showMensagemVazia = false
        if (!referencia) {
          toast({ message: 'Informe a referência', type: 'is-danger' })
          document.querySelector('#referencia').focus()
        } else {
          this.status = 'is-loading'
          try {
            if (!referencia) {
              this.page = await service.get('/api/v1/irrf/todos')
            } else {
              this.page = await service.get(
                `/api/v1/irrf/todos?referencia=${referencia}`
              )
            }
            if (this.page.length === 0) {
              const data = new Date(referencia.split('-'))
              data.setDate(0)
              data.setDate(1)
              const mesAnterior =
                data.getFullYear() +
                '-' +
                (data.getMonth() + 1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false
                })
              this.previousPage = await service.get(
                `/api/v1/irrf/todos?referencia=${mesAnterior}`
              )
              if (this.previousPage.length > 0) {
                this.configurarImportacao(referencia)
              }
              this.status = ''
              this.tabelaIrrf = new Irrf()
            } else {
              this.tabelaIrrf = new Irrf(this.page)
              this.listagemOriginal = utils.cloneDeep(this.tabelaIrrf.faixas)
              this.itemOriginal = utils.cloneDeep(this.tempIrrf)
              this.status = ''
              this.focarNoPrimeiroElementoDataTable()
            }
          } catch {
            this.status = 'is-danger'
          }
        }
      }
    },
    configurarImportacao (referencia) {
      const ref = referencia.split('-')
      this.previousPage.forEach((irrf) => {
        irrf.id = null
        irrf.data = new Date(ref[0], ref[1] - 1, '01')
        irrf.data.setMinutes(-irrf.data.getTimezoneOffset())
      })
      this.abrirModalImportacao = true
    },

    confirmarImportacao () {
      if (!this.showModal) {
        this.tabelaIrrf = new Irrf(this.previousPage)
        this.status = ''
        this.carregando = true
        service.save('/api/v1/irrf/lista?verificar=nao', this.previousPage).then((res) => {
          this.carregar(this.referencia).then((res) => {
            this.abrirModalImportacao = false
            this.carregando = false
          })
        })
      } else {
        this.tempIrrf = new Irrf(this.previousPage)
        this.abrirModalImportacao = false
        this.carregando = false
      }
    },

    _formatarDataNoGrid (data) {
      data = new Date(data)
      data = data.setHours(data.getHours() + 3)
      return new Intl.DateTimeFormat('pt-br').format(new Date(data))
    },

    _formatarColunaValor (valor) {
      return Number(valor).toLocaleString('pt-br', { minimumFractionDigits: 2 })
    },

    async alterarReferencia (aumentar = true) {
      const valor = aumentar ? 1 : -1
      const mes = Number(this.referenciaDate.getMonth() + valor)
      this.referenciaDate.setMonth(mes)
      this.getReferencia()
      await this.carregar(this.referencia)
      this.input = []
    },

    getReferencia () {
      let mes = this.referenciaDate.getMonth() + 1
      const ano = this.referenciaDate.getFullYear()
      if (mes < 10) {
        mes = `0${mes}`
      }
      this.referencia = `${ano}-${mes}`
    },
    ativarModal () {
      if (this.page.length > 0) {
        this.tempIrrf = new Irrf(this.page)
      } else {
        this.criarTabelaVazia()
      }
      this.showModal = true
      this.focarEmQualquerElemento('div.alt-modal-card div[data-group="referencia"] input')
    },
    async aoGravar () {
      const listaDeIrrf = []
      const dadosComuns = {
        data: this.referenciaDate,
        idadeMaxima: this.tempIrrf.idadeMaxima,
        numeroDependentes: this.tempIrrf.numeroDependentes
          ? this.tempIrrf.numeroDependentes
          : 0,
        vlDependente: this.tempIrrf.vlDependente
          ? parseFloat(this.tempIrrf.vlDependente).toFixed(2)
          : 0,
        vlDeducao: this.tempIrrf.vlDeducao,
        vlIrrfMin: this.tempIrrf.vlIrrfMin
          ? parseFloat(this.tempIrrf.vlIrrfMin).toFixed(2)
          : 0
      }
      const ref = this.referencia.split('-')
      this.tempIrrf.faixas.forEach((e) => {
        if (e.vlAte) {
          const irrf = new Irrf()
          Object.assign(irrf, dadosComuns, e)
          if (
            irrf.aliquota !== null &&
            irrf.aliquota !== undefined &&
            irrf.aliquota.replace
          ) {
            irrf.aliquota = parseFloat(irrf.aliquota.replace(',', '.')).toFixed(
              2
            )
          }
          irrf.data = new Date(ref[0], ref[1] - 1, '01')
          irrf.data.setMinutes(-irrf.data.getTimezoneOffset())
          listaDeIrrf.push(irrf)
        }
      })
      if (this.input instanceof Array && this.input.length > 0) {
        this.input[0].focus()
      } else {
        const itensParaExcluir = this.faixasParaExclusao.length > 0
        const itensParaSalvar = listaDeIrrf.length > 0
        if (itensParaExcluir) {
          const joinIds = this.faixasParaExclusao.join(',')
          await service.delete('/api/v1/irrf', null, joinIds)
          this.faixasParaExclusao = []
        }
        if (itensParaSalvar) {
          delete listaDeIrrf.faixas
          this.page = await service.post('/api/v1/irrf/lista', listaDeIrrf)
        }
        if (itensParaExcluir || itensParaSalvar) {
          await this.carregar(this.referencia)
        }
        this.showModal = false
        this.dadosInalterados()
      }
    },

    tratarErroFaixa (event) {
      const ordenar = { value: false }
      if (event instanceof HTMLInputElement) {
        if (!this.input.includes(event)) {
          this.input.push(event)
          ordenar.value = true
        }
      } else if (typeof event === 'string') {
        const index = this.input.findIndex((e) => e.id.includes(event))
        if (index > -1) {
          this.input.splice(index, 1)
          ordenar.value = true
        }
      }
      if (ordenar.value) {
        this.input.sort((a, b) => {
          if (a.id > b.id) {
            return 1
          } else if (a.id < b.id) {
            return -1
          } else {
            return 0
          }
        })
      }
    },
    setReferenciaDate () {
      const dateArr = this.referencia.split('-')
      this.referenciaDate = new Date(dateArr[0], dateArr[1], 0)
    },
    aoCancelarModal () {
      this.showModal = false
    },
    aoCancelar () {
      const existeModificacao = this.verificarModificaoNoItem()
      if (!existeModificacao) {
        this.tempIrrf = new Irrf()
        this.tempIrrf.faixas = [
          new Faixa(),
          new Faixa(),
          new Faixa(),
          new Faixa(),
          new Faixa()
        ]
        this.input = []
        this.faixasParaExclusao = []
        this.showModal = false
        this.$nextTick(() => {
          this.focarNoPrimeiroElementoDataTable()
        })
      }
    },
    aoExcluirFaixa (event) {
      const { faixa, key } = event
      if (faixa.id) {
        this.faixasParaExclusao.push(faixa.id)
      }
      const identificador = `irrf-tabela-faixa-${key}`
      this.input = this.input.filter((e) => {
        return !e.id.includes(identificador)
      })
    },
    async aoPesquisar (event) {
      this.termoPesquisado = event.trim()
      await this.carregar(this.referencia)
    },
    async buscar (search, referencia) {
      this.status = 'is-loading'
      search = encodeURIComponent(search)
      try {
        const matchingObjects = []
        for (const element of this.listagemOriginal) {
          const obj = element
          for (const key in obj) {
            const value = obj[key]
            const replacer = this._formatarColunaValor(value).replaceAll('.', '').includes(search.replace('%2C', ',').replaceAll('.', ''))
            if (replacer && key !== 'id') {
              matchingObjects.push(obj)
              break
            }
          }
        }
        if (matchingObjects.length > 0) {
          this.tabelaIrrf.faixas = utils.cloneDeep(matchingObjects)
          this.showMensagemVazia = false
          this.focarNoPrimeiroElementoDataTable()
        } else {
          this.tabelaIrrf.faixas = []
          this.showMensagemVazia = true
        }
        this.status = ''
      } catch {
        this.status = 'is-danger'
      }
    },
    customHighlighters (value, busca, item) {
      const stringfied = '' + value

      busca = String(busca).trim()

      const stringfiedWithoutThePoints = stringfied.replaceAll('.', '')

      if (
        !utils
          .removeAcentos(stringfiedWithoutThePoints)
          .toLowerCase()
          .includes(utils.removeAcentos(busca).toLowerCase())
      ) {
        return value
      }

      const init = utils.removeAcentos(stringfiedWithoutThePoints).toLowerCase().indexOf(utils.removeAcentos(busca).toLowerCase())
      const end = init + busca.length + (busca.length > 0 ? stringfied.length - stringfiedWithoutThePoints.length : 0)
      const extractedValue = stringfied.substring(init, end)

      return stringfied.replace(
        extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
      )
    },
    direcionaAjuda () {
      this.$nextTick(() => {
        window.open(this.linkAjuda, '_blank').focus()
      })
    },
    ligarAtalhosListagem () {
      const section = 'listagemIrrf'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_NOVO, this.ativarModal)
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
    },
    verificarModificaoNoItem () {
      const item = utils.cloneDeep(this.tempIrrf)
      const itemOriginal = utils.cloneDeep(this.itemOriginal)
      this.formatarCadaItem(item, itemOriginal)
      const existeModificacao = !utils.isTotalmenteEqual(item, itemOriginal)

      if (existeModificacao) {
        this.$root.dadosAlterados = true
        this.$root.mostrarModalDadosAlterados = true
      } else {
        this.$root.dadosAlterados = false
      }

      return existeModificacao
    },
    formatarCadaItem (item, itemOriginal) {
      delete item.id
      item.faixas.forEach(element => {
        delete element.id
      })
      delete itemOriginal.id
      itemOriginal.faixas.forEach(element => {
        delete element.id
        this.formatarCadaElemento(element)
      })
    },
    formatarCadaElemento (elemento) {
      Object.keys(elemento).forEach((key) => {
        if (isNaN(elemento[key]) || elemento[key] === 'NaN') {
          delete elemento[key]
        } else {
          elemento[key] = key !== 'aliquota' ? parseFloat(elemento[key]).toFixed(2) : parseFloat(elemento[key]).toFixed(2).replace('.', ',')
        }
      })
    },
    sairSemSalvar () {
      this.showModal = false
      this.dadosInalterados()
    },
    criarTabelaVazia () {
      this.tempIrrf = new Irrf()
      this.tempIrrf.faixas = [
        new Faixa(),
        new Faixa(),
        new Faixa(),
        new Faixa(),
        new Faixa()
      ]
    }
  },
  watch: {
    'page' (val) {
      if (val && val.length) {
        if (val.length > 0) {
          this.tempIrrf = new Irrf(val)
        }
      } else {
        this.criarTabelaVazia()
      }
      this.itemOriginal = utils.cloneDeep(this.tempIrrf)
    },
    showModal (val) {
      if (val) {
        this.listagemVisivel = false
      } else {
        this.dadosInalterados()
        this.setActiveSection('listagemIrrf')
      }
    }
  },

  async mounted () {
    this.showTitle = this.$router.currentRoute.value.fullPath === '/irrf'
    this.referenciaDate = new Date()
    this.referenciaDate.setDate(1)
    this.getReferencia()
    this.ligarAtalhosListagem()
    this.dadosInalterados()
    const { parametrosModalSalvarMudancas } = this.$root
    parametrosModalSalvarMudancas.eventoSalvar = () => {
      this.aoGravar()
      this.dadosInalterados()
    }
    parametrosModalSalvarMudancas.eventoSair = this.sairSemSalvar
    await this.carregar(this.referencia)
  }
}
</script>

<style scoped>
span {
  font-weight: lighter;
}
#irrfMinimo {
  margin: 0px 0px 8px;
}
</style>
