<template>
  <InputText
    v-model="input"
    style="text-align: right"
    :maxlength="maxLength"
    @update:erro="$emit('update:erro', $event)"
    @input="handleInput"
    @keypress="handleKeyPress"
    @blur="handleBlur"
  />
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, watch } from 'vue'
import InputText from '@alterdata/component-vue/InputText'
import { utils } from '../../../util/utils'

const emits = defineEmits(['update:modelValue', 'update:erro'])

const props = defineProps({
  modelValue: String,
  maxLength: {
    type: String || Number,
    default: 10
  }
})

const input = ref(props.modelValue)

const tempInput = ref(props.modelValue)

function handleInput (event) {
  if (event instanceof InputEvent && event.target instanceof HTMLInputElement) {
    event.preventDefault()
    const { value } = event.target
    tempInput.value = toFloat(value)
  }
}

function formatModelValue () {
  // eslint-disable-next-line eqeqeq
  if (props.modelValue == 0 || props.modelValue == '0,00') {
    input.value = toFloat(0).replace('.', ',')
  } else {
    input.value = props.modelValue
  }
}

function handleBlur () {
  if (!isNaN(parseFloat(tempInput.value))) {
    input.value = toFloat(tempInput.value)
    emits('update:modelValue', input.value)
    if (input.value) {
      input.value = input.value.replace('.', ',')
    } else {
      formatModelValue()
    }
  } else {
    emits('update:modelValue', undefined)
  }
}

function handleKeyPress (event) {
  utils.onlyForRegex(event, /[\d,]+/g)
  _handleNextValue(event)
}

function _handleNextValue (event) {
  if (
    event instanceof KeyboardEvent &&
    event.target instanceof HTMLInputElement
  ) {
    const { value, selectionStart, selectionEnd } = event.target

    const valueStart = value.substring(0, selectionStart)
    const valueEnd = value.substring(selectionEnd, value.length)
    const virtualValue = valueStart + event.key + valueEnd

    const fVirtualValue = parseFloat(virtualValue.replace(',', '.'))
    const numberMax = '9'.padEnd(props.maxLength, '9')

    _validityVirtualValue(fVirtualValue, numberMax, event, value, virtualValue)
  }
}

function _validityVirtualValue (fVirtualValue, numberMax, event, value, virtualValue) {
  if (fVirtualValue > Number(numberMax)) {
    event.preventDefault()
  } else {
    if (value.includes(',')) {
      if (event.key !== ',') {
        const valueArr = virtualValue.split(',')
        if (valueArr.length > 1 && valueArr[1].length > 2) {
          event.preventDefault()
        }
      } else {
        event.preventDefault()
      }
    }
  }
}

function toFloat (value) {
  const strValue = String(value)
  if (strValue.includes(',')) {
    if (strValue === ',') {
      return '0.00'
    } else {
      return parseFloat(strValue.replace(',', '.')).toFixed(2)
    }
  } else {
    return parseFloat(strValue).toFixed(2)
  }
}

onMounted(() => {
  if (
    props.modelValue ||
    // eslint-disable-next-line eqeqeq
    props.modelValue == '0' ||
    props.modelValue === '0,00'
  ) {
    input.value = toFloat(props.modelValue)
    emits('update:modelValue', input.value)
    if (String(input.value).includes('.')) {
      input.value = String(input.value).replace('.', ',')
    }
  } else {
    formatModelValue()
  }
})

watch(
  () => props.modelValue,
  () => {
    if (
      props.modelValue ||
      // eslint-disable-next-line eqeqeq
      props.modelValue == '0' ||
      props.modelValue === '0,00'
    ) {
      input.value = toFloat(props.modelValue).replace('.', ',')
      if (!tempInput.value) {
        tempInput.value = input.value
      }
    } else {
      input.value = props.modelValue
    }
  }
)
</script>
