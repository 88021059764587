<template>
  <div style="overflow: hidden">
    <div class="level-left">
      <input-data
        :key="a++"
        type="month"
        label="Referência"
        id="referencia"
        maxlength="4"
        v-model="this.referencia"
        data-group="data-referencia-modal"
        @change="$emit('change-referencia')"
      />
      <div style="position: relative; top: 4px">
        <alt-button
          iconOnly="angle-left"
          @click="$emit('change-referencia', false)"
        />
        <alt-button
          iconOnly="angle-right"
          @click="$emit('change-referencia', true)"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-texto
          :disabled="criando"
          label="Número de dependentes"
          v-model.number="item.numeroDependentes"
          maxlength="3"
          style="text-align: right"
          id="irrf-tabela-nm-dependentes"
          @keypress="onlyNumber($event, true)"
          @paste="aoColar"
        />
      </div>
      <div class="column">
        <InputMonetary
          :disabled="criando"
          label="Valor por dependente"
          v-model="item.vlDependente"
          id="irrf-tabela-vl-dependente"
          @paste="aoColar"
        />
      </div>
      <div class="column">
        <InputMonetary
          :disabled="criando"
          label="IRRF mínimo"
          v-model="item.vlIrrfMin"
          id="irrf-tabela-vl-irrf-min"
          @paste="aoColar"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <Tabela
          ref="tabela"
          :p-faixas="this.pItem.faixas"
          :p-erros="this.pErros"
          @erro-faixa="$emit('erro-faixa', $event)"
          @atualizar-lista="$emit('atualizar-lista')"
          @adicionar-faixa-para-exclusao="
            $emit('adicionar-faixa-para-exclusao', $event)
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@alterdata/component-vue/Button'
import InputDate from '@alterdata/component-vue/InputDate'
import InputText from '@alterdata/component-vue/InputText'
import InputMonetary from '@packonline/packonline-reutilizaveis-frontend/components/InputMonetary.vue'
import Irrf from './model/Irrf'
import Tabela from './Tabela/index.vue'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'

export default {
  components: {
    'alt-button': Button,
    'input-data': InputDate,
    'input-texto': InputText,
    Tabela,
    InputMonetary
  },

  emits: ['erro-faixa', 'atualizar-lista', 'adicionar-faixa-para-exclusao', 'gravar', 'cancelar'],
  inject: ['setActiveSection', 'addShortcut', 'direcionaAjuda'],
  props: {
    pItem: Irrf,
    pReferencia: String,
    pReferenciaDate: Date,
    pErros: Array
  },
  data () {
    return {
      criando: false,
      item: this.pItem,
      itemOriginal: null,
      referenciaDate: this.pReferencia,
      referencia: this.pReferencia,
      shortkeys: shortkeys
    }
  },

  mixins: [mixin],

  methods: {
    aoColar (event) {
      if (
        event instanceof ClipboardEvent &&
        event.target instanceof HTMLInputElement
      ) {
        const { target } = event
        const valorColado = event.clipboardData.getData('text')
        const regex = {
          'irrf-tabela-nm-dependentes': /^(?:\d{1,3})$/,
          'irrf-tabela-vl-dependente': /^(?:\d{1,10},\d{1,2}|\d{1,10})$/,
          'irrf-tabela-vl-irrf-min': /^(?:\d{1,10},\d{1,2}|\d{1,10})$/
        }
        if (!regex[target.id].test(valorColado)) {
          event.preventDefault()
        }
      }
    },
    ligarAtalhosModal () {
      const section = 'modalTabelaIrrf'
      this.setActiveSection(section)
      this.addShortcut(section, shortkeys.KEY_NOVO, this.$refs.tabela.adicionarNovaFaixa)
      this.addShortcut(section, shortkeys.KEY_GRAVAR, () => this.$emit('gravar'))
      this.addShortcut(section, shortkeys.KEY_ESC, () => this.$emit('cancelar', 'fechar'))
      this.addShortcut(section, shortkeys.KEY_AJUDA, this.direcionaAjuda)
      this.addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, this.direcionaAjuda)
    }
  },

  watch: {
    pItem (val) {
      this.item = val
    },
    pReferencia (val) {
      this.referencia = val
    }
  },
  mounted () {
    this.itemOriginal = utils.cloneDeep(this.item)
    const modal = document.querySelector('.alt-modal-card')
    const modalBody = document.querySelector('.alt-modal-card-body')
    if (modal && modal instanceof HTMLDivElement) {
      modal.style.minHeight = '100%'
    }
    if (modalBody && modalBody instanceof HTMLElement) {
      modalBody.style.paddingBottom = '10px'
      modalBody.style.width = '100%'
    }
    this.$nextTick(() => {
      this.ligarAtalhosModal()
    })
  }
}
</script>

<style>
</style>
