<template>
  <div v-if="exibe" class="columns">
    <div class="column">
      <InputMonetary
        label="De"
        maxLength="14"
        v-model="faixa.vlDe"
        :id="identificadorDe"
        :name="identificadorDe"
        :message="erro.vlDe"
        :status="this.getStatus(erro.vlDe)"
        @focus="aoEntrarNoCampo"
        @update:erro="aoLimparErro('vlDe', this.identificadorDe)"
        @blur="aoSairDoCampo($event, 'vlDe')"
      />
    </div>
    <div class="column">
      <InputMonetary
        label="Até"
        maxLength="14"
        v-model="faixa.vlAte"
        :id="identificadorAte"
        :name="identificadorAte"
        :message="erro.vlAte"
        :status="this.getStatus(erro.vlAte)"
        @update:erro="aoLimparErro('vlAte', this.identificadorAte)"
        @blur="aoSairDoCampo($event, 'vlAte')"
      />
    </div>
    <div class="column is-2">
      <InputPorcentagem
        label="Alíquota"
        v-model="faixa.aliquota"
        :id="identificadorAliquota"
        :name="identificadorAliquota"
        :message="erro.aliquota"
        :status="this.getStatus(erro.aliquota)"
        @update:erro="aoLimparErro('aliquota', this.identificadorAliquota)"
        @blur="aoSairDoCampo($event, 'aliquota')"
      />
    </div>
    <div class="column is-3">
      <InputMonetary
        label="Valor do desconto"
        maxLength="14"
        v-model="faixa.vlDesconto"
        :id="identificadorVlDesc"
        :name="identificadorVlDesc"
        :message="erro.vlDesconto"
        :status="this.getStatus(erro.vlDesconto)"
        @update:erro="aoLimparErro('vlDesconto', this.identificadorVlDesc)"
        @blur="aoSairDoCampo($event, 'vlDesconto')"
      />
    </div>
    <div class="column" style="position: relative; right: 0; top: 20px">
      <Button caption="Excluir" icon="trash" @click="aoExcluir"/>
    </div>
  </div>
</template>

<script>
import InputPorcentagem from '../../../components/InputPorcentagem'
import InputMonetary from '../../../components/alt-component/InputMonetary'
import Faixa from '../model/Faixa'
import { mixin } from '../../../util/mixin'
import { isNullOrUndefined } from 'url/util'
import Button from '@alterdata/component-vue/Button/index.vue'

export default {
  components: {
    InputPorcentagem,
    InputMonetary,
    Button
  },

  emits: ['erro-faixa', 'atualizar-faixas', 'excluir-faixa', 'atualizar-proxima-faixa'],

  props: {
    pFaixa: Faixa,
    pKey: String || Number
  },

  data () {
    return {
      faixa: this.pFaixa,
      erro: {
        idadeMaxima: '',
        numeroDependentes: '',
        vlDependente: '',
        vlDeducao: '',
        vlIrrfMin: '',
        vlDe: '',
        vlAte: '',
        aliquota: '',
        vlDesconto: ''
      },
      exibe: true,
      identificadorDe: `irrf-tabela-faixa-${this.pKey}-1-de`,
      identificadorAte: `irrf-tabela-faixa-${this.pKey}-2-ate`,
      identificadorAliquota: `irrf-tabela-faixa-${this.pKey}-3-al`,
      identificadorVlDesc: `irrf-tabela-faixa-${this.pKey}-4-vl-desc`
    }
  },

  mixins: [mixin],

  methods: {
    aoSairDoCampo (event, campo) {
      const { target } = event
      this._verificaValoresPreenchidos(campo)
      if (campo === 'vlAte' || campo === 'vlDe') {
        // eslint-disable-next-line eqeqeq
        if (target.value || target.value == '0' || target.value == '0,00') {
          this._verificarValorDeAte(target, campo)
          this._verificaValorAnterior(target, campo)
        }
        this.$emit('atualizar-faixas')
      }
    },

    _verificaValorAnterior (target, campo) {
      if (campo !== 'aliquota' && campo !== 'vlDesconto') {
        const arryId = target.id.split('-')
        if (arryId) {
          const id = arryId[3]
          if (campo === 'vlDe') {
            this._tratarSeForCampoVlDe(target, id)
          } else if (campo === 'vlAte') {
            this._tratarSeForCampoVlAte(target, id)
          }
        }
      }
    },

    _tratarSeForCampoVlDe (target, id) {
      const campo = 'vlDe'
      // eslint-disable-next-line eqeqeq
      if (id != 0) {
        const faixaAnterior = this.$parent.faixas[id - 1]
        if (
        // eslint-disable-next-line eqeqeq
          target.value || target.value == '0' || target.value == '0.00'
        ) {
          const value = parseFloat(target.value.replace(',', '.'))
          const campoComparado = 'vlAte'
          const valueFaixaAnterior = parseFloat(
            faixaAnterior[campoComparado]
          )
          if (valueFaixaAnterior >= value) {
            this.$emit('erro-faixa', target)
            this.erro[campo] =
                    'O valor informado deve ser superior a faixa de valores anterior'
          } else {
            this.$emit('erro-faixa', target.id.replace('input#', ''))
            this.erro[campo] = ''
          }
        }
      }
    },

    _tratarSeForCampoVlAte (target, id) {
      const campo = 'vlAte'
      if (this.$parent.faixas.length - 1 > Number(id) + 1) {
        const faixaPosterior = this.$parent.faixas[Number(id) + 1]
        if (
        // eslint-disable-next-line eqeqeq
          target.value || target.value == '0' || target.value == '0.00'
        ) {
          const value = parseFloat(target.value.replace(',', '.'))
          const campoComparado = 'vlDe'
          if (faixaPosterior[campoComparado]) {
            const valueFaixaPosterior = parseFloat(
              faixaPosterior[campoComparado]
            )
            if (valueFaixaPosterior <= value) {
              this.$emit('erro-faixa', target)
              this.erro[campo] =
                      'O valor informado deve ser inferior a faixa de valores seguinte'
            } else {
              this.$emit('erro-faixa', target.id.replace('input#', ''))
              this.erro[campo] = ''
            }
          }
        }
      }
    },

    _verificaValoresPreenchidos () {
      const result = this._verificaPropriedadesFaixa()
      this._atribuiELimpaErros(
        result,
        'vlDe',
        this.identificadorDe,
        'Informe o valor inicial desta faixa'
      )
      this._atribuiELimpaErros(
        result,
        'vlAte',
        this.identificadorAte,
        'Informe o valor final desta faixa'
      )
      this._atribuiELimpaErros(
        result,
        'aliquota',
        this.identificadorAliquota,
        'Informe a alíquota desta faixa'
      )
      this._atribuiELimpaErros(
        result,
        'vlDesconto',
        this.identificadorVlDesc,
        'Informe o desconto desta faixa'
      )
      if (!result) {
        this.erro = {
          vlDe: '',
          vlAte: '',
          aliquota: '',
          vlDesconto: ''
        }
      }
    },
    _verificaPropriedadesFaixa () {
      const entriesFaixa = Object.entries(this.faixa)
      const possuiCampoPreenchido = { value: false }
      entriesFaixa.forEach((e) => {
        if (e[0] !== 'id' && !isNullOrUndefined(e[1]) && e[1] !== '') {
          possuiCampoPreenchido.value = true
        }
      })
      return possuiCampoPreenchido.value
    },

    _atribuiELimpaErros (result, propriedade, identificador, mensagem = '') {
      if (!this.faixa[propriedade] && result) {
        this.erro[propriedade] = mensagem
        const input = document.querySelector('input#' + identificador)
        this.$emit('erro-faixa', input)
      } else {
        if (this.erro[propriedade] === mensagem) {
          this.erro[propriedade] = ''
          const input = document.querySelector('input#' + identificador)
          this.$emit('erro-faixa', input.id)
        }
      }
    },

    aoLimparErro (campo, identificador) {
      this.erro[campo] = ''
      this.$emit('erro-faixa', identificador)
    },

    verificaDados () {
      const inputDe = document.querySelector('input#' + this.identificadorDe)
      const inputAte = document.querySelector('input#' + this.identificadorAte)

      this._verificaValoresPreenchidos('vlDe')
      this._verificaValoresPreenchidos('vlAte')

      // eslint-disable-next-line eqeqeq
      if (inputDe.value || inputDe.value == '0' || inputDe.value == '0,00') {
        this._verificaValorAnterior(inputDe, 'vlDe')
      }
      // eslint-disable-next-line eqeqeq
      if (inputAte.value || inputAte.value == '0' || inputAte.value == '0,00') {
        this._verificaValorAnterior(inputAte, 'vlAte')
      }
      this._verificarValorDeAte(inputDe, 'vlDe')
      this._verificarValorDeAte(inputAte, 'vlAte')
    },
    _verificarValorDeAte (target, campo) {
      const msg1 = 'O valor informado deve ser inferior ao valor do campo \'Até\''
      const msg2 = 'O valor informado deve ser superior ao valor do campo \'De\''
      if (Number(this.faixa.vlDe) >= Number(this.faixa.vlAte)) {
        if (campo === 'vlDe') {
          this.erro.vlDe = msg1
        } else {
          this.erro.vlAte = msg2
        }
        this.$emit('erro-faixa', target)
      } else {
        if (this.erro.vlDe === msg1) {
          this.erro.vlDe = ''
        }
        if (this.erro.vlAte === msg2) {
          this.erro.vlAte = ''
        }
      }
    },

    aoExcluir () {
      this.$emit('excluir-faixa', { faixa: this.faixa, key: this.pKey })
    },

    aoEntrarNoCampo () {
      if (!this.faixa.vlDe) {
        this.$emit('atualizar-proxima-faixa', this.pKey)
      }
    }
  },

  watch: {
    pFaixa (val) {
      this.exibe = false
      this.faixa = val
      setTimeout(() => {
        this.exibe = true
        this.erro = {
          idadeMaxima: '',
          numeroDependentes: '',
          vlDependente: '',
          vlDeducao: '',
          vlIrrfMin: '',
          vlDe: '',
          vlAte: '',
          aliquota: '',
          vlDesconto: ''
        }
      }, 50)
    }
  }
}
</script>

<style scoped>
.columns:first-child {
  padding-top: 15px;
  padding-bottom: 0;
}

.column {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
