import Faixa from './Faixa'

class Irrf {
  constructor (irrf) {
    this.id = null
    this.data = null
    this.idadeMaxima = 0
    this.numeroDependentes = null
    this.vlDependente = null
    this.vlIrrfMin = null
    this.faixas = []
    this.vlDe = 0
    this.vlAte = 0
    this.aliquota = 0
    this.vlDesconto = 0

    if (irrf instanceof Irrf) {
      Object.assign(this, irrf)
      this.data = new Date(irrf.data)
    }
    if (irrf instanceof Array && irrf.length > 0) {
      Object.assign(this, irrf[0])
      irrf.sort((a, b) => a.vlDe - b.vlDe)
      irrf.forEach(e => {
        this.faixas.push(new Faixa(e))
      })
    }
  }

  addFaixa (faixa) {
    if (faixa instanceof Faixa) {
      this.faixas.push(faixa)
    }
  }
}
export default Irrf
